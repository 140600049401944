import React, {useRef, useState, useEffect} from "react";
import "./styles.css";
import {ReactReader, ReactReaderStyle} from "react-reader";
import Ebook from "./epub/poetry.epub";


const ownStyles = {
    ...ReactReaderStyle,
    arrow: {
        ...ReactReaderStyle.arrow,
        color: "gray"
    }
};

//const loc = "epubcfi(/6/4[chapter1]!/4/2[chapter1]/8[s3]/6/1:490)";
const loc = null;

function Poetry() {

    const [selections, setSelections] = useState([]);
    const renditionRef = useRef(null);

    const [location, setLocation] = useState(loc);
    const locationChanged = (epubcifi) => {
        // epubcifi is a internal string used by epubjs to point to a location in an epub. It looks like this: epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
        setLocation(epubcifi);
        console.log(location);
    };

    useEffect(() => {
        if (renditionRef.current) {
            function setRenderSelection(cfiRange, contents) {
                setSelections(
                    selections.concat({
                        text: renditionRef.current.getRange(cfiRange).toString(),
                        cfiRange
                    })
                );
                renditionRef.current.annotations.add(
                    "highlight",
                    cfiRange,
                    {},
                    null,
                    "hl",
                    {
                        fill: "yellow",
                        "fill-opacity": "0.5"
                    }
                );
                contents.window.getSelection().removeAllRanges();
            }

            renditionRef.current.on("selected", setRenderSelection);
            console.log(selections);
            return () => {
                renditionRef.current.off("selected", setRenderSelection);
            };
        }
    }, [setSelections, selections]);


    function removeIntro() {
        let elem = document.getElementById('clear');
        elem.parentNode.removeChild(elem);
    }

    return (
        <>
            {/*Opening Page with SVG*/}
            <div  id="clear" className="poetry">
                <svg viewBox="-230 0 3500 1800">
                    <path className="path" fill="white" stroke="#BDCFE7" strokeWidth="8" d="M2542,1704,c-61-200-241-485-413-655-115-113-282-209-365
-209-20,0-22,4-17,40,7,47-15,87-52,96-38,9-30,23,17,30,55,9,91,44,86
85-2,24,12,49,70,128,39,55,72,104,72,110,0,6-9,11-19,11-14,0-54-48
-134-157-115-160-139-181-172-153-12,10-9,16,15,37,17,14,30,35,30
46,0,26-26,60-57,76-29,15-32,45-4,36,29-9,51,30,51,88,0,27,5,57,10,68
24,43-36,89-116,89-39,0-51-5-81-35-33-33-35-39-31-86,5-47,3
-51-26-71-50-34-54-44-49-111,6-65,24-97,54-97,14,0,19-9,21-42
l3-43,83-21,c46-11,83-24,83-27,1-24-11-24-56-2-55,27-129,32
-156,12-48-36-76-136-47-165,15-15,97-16,133-2,21,8,26,7,22-2-3
-10-41-13-153-12-147,1-197,8-341,50,l-53,15,35,33,c40,38,45,74,15
101-14,13-17,22-10,29,16,16,12,52-10,71-11,10-20,25-20,34-1,16-23
51-33,51-3,0-8-13-12-29-7-33-35-45-83-35,l-30,6,15-48,c9-27
20-56,24-66,5-10,3-21-6-28-17-14-20-46-5-55,19-12,11-26-9
-15-28,14-38,13-61-10-15-15-20-33-20-72,0-72-22-49-28,30-5
66,4,79,58,84,l35,3-49,23,c-68,31-125,80-171,146-21,31-42,56-47,56
-19,0,20-178,57-259,37-79,105-170,180-239,69-63,187-132,226-132,13
0,67,11,119,25,52,14,105,25,118,25,l22,0-26-40,c-19-29-32-39-43-35
-43,18-85,14-195-14-65-17-127-31-138-31-11,0-72,18-136,39-184
62-177,62-407-9-39-12-101-22-142-23-67-1-74,1-112,31-22,18
-43,32-47,32-14,0,41-52,70-67,45-24,169-12,289,26,152,50,172,50,315,2
145-49,190-51,317-16,89,25,144,30,177,17,15-6,13-15-23-71-38-60
-39-64-22-77,50-39,50-39,128,31,l74,66,68-22,c88-28,185-27,276,2,73
23,171,78,169,94,0,6-19,14-42,19-33,7-58,5-121-14-64-18-96-22
-169-18-86,4-126,17-101,32,6,4,11,28,11,53,0,40,7,55,57,121,46,59,68,79
109,97,64,28,77,45,34,45-28,0-30,2-20,20,7,14,7,26,1,39-10,18-6,31,11
31,4,0,8-7,8-15,0-20,24-19,44,1,21,20,20,23-6,51-26,28-14,30,29,3,37
-22,49-54,34-94-18-46-15-49,41-42,230,31,512,305,705,684,29,57,60
128,70,158,9,30,18,52,20,51,1-2,7-54,12-115,24-266-40-557-168-770
-53-88-129-182-172-213-27-19-31-19-75-4-64,22-91,13-165-51
-34-29-71-54-82-54-17,0-18-2-6-16,10-13,27-15,76-12,49,3,81,13
147,47,l85,43,62-39,c86-53,166-57,329-17,144,36,242,37,314,6,27-12,56
-22,64-22,29,0-1,18-65,39-86,29-176,27-310-5-59-15-139-27-178
-28-63,0-77,3-138,37-37,21-67,40-67,42,0,2,25,28,56,57,226,214,359
666,303,1031-16,105-23,110-47,31z,m-1023-259,c-48-26-75-117-49-166
9-16,5-25-25-53-32-31-35-38-35-92,0-55-1-57-17-41-13,13-18
35-19,78-1,57,0,60,37,90,36,28,38,32,36,83-2,57,16,89,58,107,33,13,42,9
14-6z,m86-30,c14-13,25-28,25-32,0-5-28-22-62-38-51-24-62-34
-59-50,10-50,6-58-39-81,l-45-24,37,33,c37,32,37,32,22,66-18,43-11
81,23,121,31,37,65,39,98,5z,m25-138,c-11-36-13-38-42-32-30,7-30,6
-24-21,4-16,23-42,42-57,42-35,45-68,7-94-24-17-33-18-63-7-25
8-36,18-38,35-9,62-38,27-30-37,3-25,13-34,73-64,85-43,79-39,74
-45-3-2-43,6-89,18,l-84,22-8,40,c-5,22-7,64-6,94,3,53,5,56,43,76,37
19,40,23,39,62-1,41,0,42,55,66,56,24,56,24,59,4,2-12-1-38-8-60z,m254
-9,c-121-168-155-226-109-188,18,15,19,1,1-23-13-16-73-43-80-36
-6,6,207,299,217,299,5,0-8-24-29-52z,m-912-218,c16-22,16-26,2-48
-15-23-15-26,11-46,27-22,27-22,11-55-11-20-32-39-54-48,l-36
-15,26-14,c82-43,346-89,465-82,80,5,79,7,26-59-23-29-46-46-72-53
-21-5-46-19-56-30-10-11-30-20-44-20-14,0-69-11-121-25-121
-31-146-31-208,3-174,94-305,262-353,453-12,44-19,83-16,85,3,3,14
-11,26-30,26-42,117-126,137-126,18,0,18-8-1-26-24-25-29-64-14
-123,20-79,41-75,54,9,11,72,23,81,76,60,40-17,47-6,20,31,l-23,31,22,23
c12,13,17,26,12,29-5,3-12,18-14,33-3,16-9,37-13,47-7,16-5,18,8,13
33-13,77,4,84,32,l6,26,11-25,c6-14,19-36,28-50z,m580,0,c13,0,18-8,18
-25,0-25,0-25-27-8-31,18-53,42-53,55,0,5,10,2,22-6,12-9,30-16,40
-16z,m95-65,c-6-7-18-10-25-7-10,3-11,7-2,12,8,5,2,10-14,14-14,3
-26,12-26,20,0,10,9,9,40-6,31-16,37-22,27-33z,m19-64,c-10-16-23-14
-30,5-9,25,4,38,21,21,9-8,12-20,9-26z,m-141,7,c30-13,55-26,55-29,0
-3-16-27-35-52-30-39-43-47-89-58-96-21-126,2-100,77,16,46,56
84,89,84,14,0,49-10,80-22z,m71-71,c-5-23-33-43-43-33-3,2,5,18,18
35,25,35,34,34,25-2z,m-82-109,c-4-7-25-35-48-62-31-38-41-60-47
-103-4-31-12-60-17-65-5-5-29,4-61,23-59,37-56,48,14,72,29,10,54
32,94,80,29,37,58,67,63,67,5,0,6-6,2-12z,m651-148,c37-14,23-29-63-68
-58-27-178-56-162-40,3,3,36,31,74,62,71,58,98,66,151,46z,m-807-89,l21
-16-24-28-25-28-50,17,c-27,9-50,19-50,23,0,3,11,23,26,44,25,37,25,37
53,21,15-9,37-24,49-33z,m472-20,c0-9-111-61-175-82-58-19-179
-15-248,7,l-57,19,23,29,c21,26,26,27,47,16,13-7,62-15,109-17,68-4,98,0
146,16,61,20,155,28,155,12z,m-567-47,c26-9,46-19,46-22-1-4-28-31
-60-61-57-53-59-53-83-37,l-25,16,36,60,c19,33,36,60,37,60,2,0,23-7
49-16z"/>
                </svg>
                    <button onClick={removeIntro} className="poetryBtn">Enter</button>
                <p className="title">100 Days of Poetry </p>
                <p className="description">one hundred famous poems <br/>with their meanings explained</p>
            </div>

            {/*Begin first poem of book*/}
            <div className="App" style={{position: "relative", top: '0', height: "100vh"}}>

                <ReactReader
                    location={location}
                    locationChanged={locationChanged}
                    url={Ebook}
                    styles={ownStyles}
                    getRendition={(rendition) => {
                        renditionRef.current = rendition;
                        renditionRef.current.themes.default({
                            "::selection": {
                                background: "white"
                            }
                        });
                        setSelections([]);
                    }}
                />
            </div>
            <div
                style={{
                    position: "absolute",
                    bottom: "1rem",
                    right: "1rem",
                    zIndex: 1,
                    backgroundColor: "white"
                }}
            >
            </div>
        </>
    );
}

export default Poetry