import { StrictMode } from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Poetry from "./Poetry";

const rootElement = document.getElementById("root");

    ReactDOM.render(
        <StrictMode>

            <Router>

                <Routes>
                    <Route path="/" exact element={<Poetry/>}/>
                </Routes>
            </Router>
        </StrictMode>,
        rootElement
    );
